<template>
  <div
    v-if="isShowShareBtn"
    class="share-block"
  >
    <a
      v-if="isShowOpenApp"
      class="btn btn-share btn-circle-icon mr-2"
      :href="appLink"
    >
      <i class="fas fa-mobile-alt" />
    </a>
    <button
      class="btn btn-share btn-circle-icon share-block-activator"
      :class="{'active': isOpen}"
      @click.stop="toogle()"
    >
      <i class="far fa-share-alt" />
    </button>
    <div
      v-click-outside="close"
      v-fix-collision
      class="share-block-detail"
    >
      <button
        class="btn btn-share"
        @click="openLink(twitterLink)"
      >
        <i class="fab fa-twitter" />
      </button>
      <button
        class="btn btn-share"
        @click="openLink(lineLink)"
      >
        <i class="fab fa-line" />
      </button>
      <button
        class="btn btn-share"
        @click="openLink(facebookLink)"
      >
        <i class="fab fa-facebook-f" />
      </button>
      <button
        class="btn btn-share"
        @click="copy"
      >
        <i
          :class="{'fas fa-check': isCopied, 'fas fa-exclamation-circle': isFailed, 'far fa-copy': !isCopied && !isFailed }"
        />
      </button>

      <button
        class="btn btn-share"
        @click="close"
      >
        <i class="far fa-times" />
      </button>
    </div>
    <textarea
      ref="copy"
      v-model="urlCopy"
      class="copy-hidden"
    />
  </div>
</template>
<script>
  import vClickOutside from 'v-click-outside';
  import {
    generateBrowserDeepLink,
    getDeepLink,
  } from 'src/utils/generateDeepLink';

  export default {
    name: 'ShareBtn',
    directives: {
      clickOutside: vClickOutside.directive,
      'fix-collision': function(el, bindings, vnode) {
        vnode.context.$nextTick(() => {
          // let log = vnode.context.logger.log;
          let maxWidth = document.documentElement.clientWidth;
          let maxHeight = document.documentElement.clientHeight;
          let {
            top,
            bottom,
            left,
            right,
            height,
            width,
          } = el.getBoundingClientRect();
          // log(
          //   `\nMaxWidth: ${maxWidth}; Left: ${left}; Right: ${right};\nTranslateX: ${translateX};`,
          // );
          let translateX =
            left < 0 ? -left : right > maxWidth ? maxWidth - right - 16 : 0;
          let translateY =
            top < 0 ? -top : bottom > maxHeight ? maxHeight - bottom - 16 : 0;
          // log(
          //   `\nMaxHeight:  ${maxHeight}; Top:   ${top}; Bottom: ${bottom};\nTranslateY: ${translateY};`,
          // );
          el.style.transform = `translate(${translateX}px, ${translateY}px)`;
          el.style.transform = `translate(${translateX}px, ${translateY}px)`;
          el.style.maxWidth = `${maxWidth}px`;
          el.style.maxHeight = `${maxHeight}px`;
        });
      },
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
      link: {
        type: String,
        default: null,
      },
    },
    data: () => ({
      isOpen: false,
      isCopied: false,
      isFailed: false,
    }),
    computed: {
      url() {
        return this.link || encodeURIComponent(this.urlCopy);
      },
      urlCopy() {
        return this.link || generateBrowserDeepLink(this.item);
      },
      facebookLink() {
        return `https://www.facebook.com/sharer/sharer.php?u=${this.url}`;
      },
      lineLink() {
        return `https://social-plugins.line.me/lineit/share?url=${this.url}`;
      },
      twitterLink() {
        return `https://twitter.com/intent/tweet?url=${this.url}`;
      },

      isShowShareBtn() {
        return this.isActiveFeature('share-btn');
      },
      isShowOpenApp() {
        return this.isActiveFeature('share-btn-open-app');
      },
      appLink() {
        return getDeepLink(this.item);
      },
    },
    methods: {
      toogle() {
        // console.log(`toogle`, this.isOpen);
        this.isOpen = !this.isOpen;
      },

      openLink(url) {
        try {
          window.open(url, null, 'width=640,height=480');
        } catch (err) {
          Object.assign(document.createElement('a'), {
            target: '_blank',
            href: url,
          }).click();
        }
        // this.$stats.send('share_item', {
        //   item_id: this.item.id,
        //   itype: this.item.itype,
        // });
        return url;
      },

      async copy() {
        if (navigator.share) {
          // NOTE: work with https/localhost
          await navigator.share({
            url: this.urlCopy,
            title: `Share`,
          });
          // this.$stats.send('share_item', {
          //   extra_data: {
          //     item_id: this.item.id,
          //     itype: this.item.itype
          //   }
          // });
          this.copyCheck();
        } else {
          try {
            if (!navigator.clipboard) {
              throw new Error(`Navigator clipboard don't supported`);
            }
            await navigator.clipboard.writeText(this.urlCopy);
            this.copyCheck();
          } catch (err) {
            console.error(err);
            // NOTE: work with https/localhost
            this.$refs.copy.focus();
            this.$refs.copy.select();
            this.$refs.copy.blur();
            let successful = document.execCommand('copy');
            if (successful) {
              this.copyCheck();
            } else {
              this.copyFailed();
            }
          }
          // if (!navigator.clipboard) {
          //   // NOTE: work with https/localhost
          //   this.$refs.copy.select();
          //   let successful = document.execCommand('copy');
          //   this.copyCheck();
          // } else {
          //   // console.log(this.url);
          //   navigator.clipboard.writeText(this.urlCopy).then(
          //     () => {
          //       this.copyCheck();
          //       console.log('Async: Copying to clipboard was successful!');
          //     },
          //     err => {
          //       console.error('Async: Could not copy text: ', err);
          //     }
          //   );
          // }
        }
      },

      close() {
        this.isOpen = false;
      },

      copyCheck() {
        // this.$stats.send('share_item', {
        //   item_id: this.item.id,
        //   itype: this.item.itype,
        // });
        this.isCopied = true;

        setTimeout(() => {
          this.isCopied = false;
        }, 5000);
      },
      copyFailed() {
        this.isFailed = true;

        setTimeout(() => {
          this.isFailed = false;
        }, 5000);
      },
    },
  };
</script>

<style lang="scss">
  // @import '~/bootstrap/scss/_utilities.scss';
  // @import '~/bootstrap/scss/_buttons.scss';
  // @import 'src/style/_shared.scss';

  .copy-hidden {
    left: -999px;
    position: fixed;
    top: -999px;
    z-index: -1;
  }

  .share-block {
    display: inline-block;
    position: relative;

    &-activator {
    }

    &-activator.active + &-detail {
      display: block;
    }

    &-detail {
      background-color: $flex-background-color;

      border-radius: 1em;
      display: none;
      margin-left: 0;
      margin-top: 4px;
      padding: 0 8px;
      position: absolute;
      text-align: center;
      width: 220px;
      z-index: 1;
    }
  }

  .btn.btn-share {
    // @extend .btn;
    // @extend .rounded-circle;
    // @extend .btn-circle-icon;
    /*min-width: 2em;*/
    //background-color: $app-background-color;
    background-color: $flex-background-color;

    &.active {
      background-color: $text-color;
      // color: $subscribe-bg;
      color: $app-background-color;
    }

    &:focus {
      box-shadow: none;
      opacity: 0.8;
      outline: none;
    }
  }
</style>
